


































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
import { EditReadingMaterialLevelName } from '@/models/reading_material';
@Component({
  components: { EmptyPlaceholder },
})
export default class ReadingMaterialList extends Vue {
  specifiedIndex = -1;
  selectedReadingMaterialLevelName = '';
  isSaveButtonDisabled = true;

  @Prop({ required: true })
  data!: string[];

  @Prop({ default: false })
  showEmptyPlaceholder!: boolean;

  get showPlaceholder() {
    return this.showEmptyPlaceholder;
  }

  editItem(item: string, index: number) {
    this.specifiedIndex = index;
    this.selectedReadingMaterialLevelName = item;
  }

  closeComponent() {
    this.specifiedIndex = -1;
    this.selectedReadingMaterialLevelName = '';
  }

  validateInput() {
    this.isSaveButtonDisabled = false;
  }

  @Emit(`update`)
  updateItemName(item: string) {
    this.specifiedIndex = -1;
    const payload: EditReadingMaterialLevelName = {
      oldName: item,
      newName: this.selectedReadingMaterialLevelName,
    };
    return payload;
  }

  @Emit(`route`)
  updateFolderRoute(payload: string) {
    return payload;
  }
}
