export function padIdWithZeroes(id: number) {
  if (!id || id < 0) {
    return ``;
  } else if (id > 999999) {
    return id.toString();
  }
  //If value is less than 7 digits, pad it with 0s
  let currentId = id;
  let zeroPadding = 0;
  while (currentId > 0) {
    zeroPadding += 1;
    currentId = Math.floor(currentId / 10);
  }
  let zeroString = '';
  const minimumPadding = 7;
  for (let index = 0; index < minimumPadding - zeroPadding; index++) {
    zeroString += '0';
  }
  return zeroString + id.toString();
}

export function formatSecondsToVideoDuration(value: number): string {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  let output = ``;

  // Seconds
  seconds = Math.floor(value % 60); // Seconds will always be the remainder

  // hours
  hours = Math.floor(value / (60 * 60));

  // Minutes
  minutes = Math.floor(value / 60 - hours * 60);

  output += hours > 9 ? `${hours}:` : `0${hours}:`;
  output += minutes > 9 ? `${minutes}:` : `0${minutes}:`;
  output += seconds > 9 ? `${seconds}` : `0${seconds}`;

  return output;
}
