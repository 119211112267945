































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import FolderNavigation from '@/components/management/FolderNavigation.vue';
import { FolderItem } from '@/models/miscellaneous';
import PaginationControls from '@/components/common/PaginationControls.vue';
import { PaginationDetails } from '@/models/generic';
@Component({
  components: { FolderNavigation, PaginationControls },
})
export default class ManagementLayout extends Vue {
  //UI State
  itemsPerPage = 10;
  currentPage = 1;

  @Prop({ required: true })
  folderNames!: FolderItem[];

  @Prop()
  totalCount!: number;

  @Prop()
  resetPagination!: boolean;

  @Prop()
  currentLimit!: number;

  @Prop()
  specifiedPage!: number;

  @Prop()
  isPageSpecified!: boolean;

  @Emit(`paginate`)
  updatePagination(payload: PaginationDetails) {
    this.currentPage = payload.page;
    this.itemsPerPage = payload.limit;
    return payload;
  }

  @Emit(`reset`)
  resetFolders(payload: FolderItem[]) {
    return payload;
  }

  @Emit(`resetPagination`)
  resetPaginationStatus(payload: boolean) {
    return payload;
  }

  @Emit(`resetCurrentPage`)
  resetCurrentPageStatus(payload: boolean) {
    return payload;
  }
}
