

















































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
import { ReadingMaterialFileType } from '@/utils/constants';
import { ReadingMaterialItem } from '@/models/reading_material';
@Component({
  components: { EmptyPlaceholder },
})
export default class ReadingMaterialList extends Vue {
  @Prop({ required: true })
  data!: ReadingMaterialItem[];

  @Prop({ default: false })
  showEmptyPlaceholder!: boolean;

  @Prop()
  previousCheckboxes!: number[];

  @Prop({ default: true })
  isCheckboxAvailable!: boolean;

  checkboxSelection: number[] = [];

  get showPlaceholder() {
    return this.showEmptyPlaceholder;
  }

  mounted() {
    this.checkboxSelection = this.previousCheckboxes;
  }

  @Watch(`previousCheckboxes`)
  updateCurrentCheckboxes() {
    this.checkboxSelection = this.previousCheckboxes;
  }

  getImageSrc(item: ReadingMaterialItem) {
    let imageSrc = ``;
    const splits = item.materialUrl.split('.');
    const fileExtension = splits[splits.length - 1];
    switch (fileExtension) {
      case ReadingMaterialFileType.pptx: {
        imageSrc = `powerpoint.svg`;
        break;
      }
      case ReadingMaterialFileType.xlsx: {
        imageSrc = `ic_excel.svg`;
        break;
      }
      case ReadingMaterialFileType.doc: {
        imageSrc = `ic_word.svg`;
        break;
      }
      case ReadingMaterialFileType.docx: {
        imageSrc = `ic_word.svg`;
        break;
      }
      case ReadingMaterialFileType.pdf: {
        imageSrc = `ic_pdf.svg`;
        break;
      }
      default: {
        imageSrc = `ic_img.svg`;
        break;
      }
    }
    if(item.materialUrl) return item.materialUrl;
    return require(`@/assets/icons/${imageSrc}`);
  }

  @Emit(`availability`)
  updateAvailability(payload: ReadingMaterialItem) {
    return payload;
  }

  @Emit(`delete`)
  deleteItem(item: ReadingMaterialItem) {
    return item;
  }

  @Emit(`edit`)
  editItem(item: ReadingMaterialItem) {
    return item;
  }

  @Emit(`checkboxUpdate`)
  updateCheckboxData() {
    return this.checkboxSelection;
  }
}
