









































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import ManagementLayout from '@/layouts/management/ManagementLayout.vue';
import { FolderItem } from '@/models/miscellaneous';
import SearchBar from '@/components/common/SearchBar.vue';
import ManagementList from '@/components/management/ManagementList.vue';
import { CourseListItem } from '@/models/management';
import { CourseStores } from '@/store/course';
import moment from 'moment';
import ReadingMaterialList from '@/components/resources/ReadingMaterialList.vue';
import UploadButton from '@/components/resources/UploadButton.vue';
import { ReadingMaterialStores } from '@/store/readingMaterials';
import { EditReadingMaterialLevelName, FetchReadingMaterialLevelsParams, ReadingMaterialFetchParams, ReadingMaterialItem } from '@/models/reading_material';
import ReadingMaterialLevelList from '@/components/resources/ReadingMaterialLevelList.vue';
import { PaginationDetails } from '@/models/generic';
import { FetchCoursesWithParams } from '@/models/courses';
@Component({
    components: { 
        Page, 
        ManagementLayout, 
        SearchBar, 
        ManagementList, 
        ReadingMaterialList,
        UploadButton,
        ReadingMaterialLevelList,
    },
})
export default class Resources extends Vue {
    // UI states
    currentFolders: FolderItem[] = [];
    currentCourses: CourseListItem[] = [];
    currentReadingMaterialLevel: string[] = [];
    currentReadingMaterial: ReadingMaterialItem[] = [];
    // Pagination
    isPaginationReset = false;
    isPageSpecified = false;
    currentPage = 1;
    currentLimit = 10;
    currentKeyword = ``;
    currentTotalCount = 10;
    // Stores
    courseListStore = CourseStores.list;
    readingMaterialListStore = ReadingMaterialStores.list;
    readingMaterialStateStore = ReadingMaterialStores.state;
    readingMaterialDetailStore = ReadingMaterialStores.detail;
    // Checkbox Selection
    subjectCheckboxes: number[] = [];
    formCheckboxes: number[] = [];
    materialCheckboxes: number[] = [];
    isDeleteButtonDisabled = true;

    get folders() {
        return this.currentFolders;
    }

    get courses() {
        return this.currentCourses;
    }

    get readingMaterialLevels() {
        return this.currentReadingMaterialLevel;
    }

    get readingMaterials() {
        return this.currentReadingMaterial;
    }

    get isCurrentRouteActive() {
        return this.$route.path === `/resources`;
    }

    mounted() {
        if (this.readingMaterialStateStore.readingMaterialFolderState.length > 0 && this.readingMaterialStateStore.isModified) {
            this.currentFolders = this.readingMaterialStateStore.readingMaterialFolderState;
            this.readingMaterialStateStore.updateReadingMaterialIsModified(false);
            switch(this.currentFolders.length) {
                case 2: {
                    this.currentCourses = this.readingMaterialStateStore.courseData;
                    this.currentPage = this.readingMaterialStateStore.currentPageState[this.currentFolders.length];
                    this.currentLimit = this.readingMaterialStateStore.currentLimitState[this.currentFolders.length];
                    this.currentTotalCount = this.readingMaterialStateStore.currentTotalCountState[this.currentFolders.length];
                    this.currentKeyword = this.readingMaterialStateStore.currentKeywordState[this.currentFolders.length];
                    this.isPageSpecified = true;
                    break;
                }
                case 3: {
                    this.currentCourses = this.readingMaterialStateStore.courseData;
                    this.currentReadingMaterialLevel = this.readingMaterialStateStore.levelData;
                    this.currentPage = this.readingMaterialStateStore.currentPageState[this.currentFolders.length];
                    this.currentLimit = this.readingMaterialStateStore.currentLimitState[this.currentFolders.length];
                    this.currentTotalCount = this.readingMaterialStateStore.currentTotalCountState[this.currentFolders.length];
                    this.currentKeyword = this.readingMaterialStateStore.currentKeywordState[this.currentFolders.length];
                    this.isPageSpecified = true;
                    break;
                }
                default: {
                    break;
                }
            }
            this.retrieveData();
        } else if (this.readingMaterialStateStore.readingMaterialFolderState.length > 0 && !this.readingMaterialStateStore.isModified) {
            this.currentFolders = this.readingMaterialStateStore.readingMaterialFolderState;
            this.currentCourses = this.readingMaterialStateStore.courseData;
            this.currentReadingMaterialLevel = this.readingMaterialStateStore.levelData;
            this.currentReadingMaterial = this.readingMaterialStateStore.readingMaterialData;
            this.currentPage = this.readingMaterialStateStore.currentPageState[this.currentFolders.length];
            this.currentLimit = this.readingMaterialStateStore.currentLimitState[this.currentFolders.length];
            this.currentTotalCount = this.readingMaterialStateStore.currentTotalCountState[this.currentFolders.length];
            this.currentKeyword = this.readingMaterialStateStore.currentKeywordState[this.currentFolders.length];
            this.isPageSpecified = true;
        } else {
            this.currentFolders.push({ id: -1, name: `Courses` });
            this.retrieveData();
        }
    }

    beforeDestroy() {
        this.saveCurrentState();
    }

    // Search 
    searchItems(payload: string) {
        this.currentPage = 1;
        this.isPaginationReset = true;
        this.currentKeyword = payload;
        this.retrieveData();
    }

    //Pagination
    updatePagination(payload: PaginationDetails) {
        this.currentPage = payload.page;
        this.currentLimit = payload.limit;
        this.retrieveData();
    }

    resetPaginationStatus(payload: boolean) {
        this.isPaginationReset = payload;
    }

    resetCurrentPageStatus(payload: boolean) {
        this.isPageSpecified = payload;
    }

    retrieveData() {
        switch(this.currentFolders.length) {
            case 1: {
                const payload: Partial<FetchCoursesWithParams> = {
                    page: this.currentPage,
                    limit: this.currentLimit,
                };
                if (this.currentKeyword.length > 0) {
                    payload.keyword = this.currentKeyword;
                }
                this.courseListStore.retrieveCoursesWithParams(payload);
                break;
            }
            case 2: {
                const payload: Partial<FetchReadingMaterialLevelsParams> = {
                    page: this.currentPage,
                    limit: this.currentLimit,
                    courseid: this.currentFolders[this.currentFolders.length - 1].id,
                };
                if (this.currentKeyword.length > 0) {
                    payload.keyword = this.currentKeyword;
                }
                this.readingMaterialListStore.retrieveReadingMaterialLevelsWithParams(payload);
                break;
            }
            case 3: {
                const payload: Partial<ReadingMaterialFetchParams> = {
                    page: this.currentPage,
                    limit: this.currentLimit,
                    courseId: this.currentFolders[this.currentFolders.length - 2].id,
                    materialLevel: this.currentFolders[this.currentFolders.length - 1].name
                };
                if (this.currentKeyword.length > 0) {
                    payload.keyword = this.currentKeyword;
                }
                this.readingMaterialListStore.retrieveReadingMaterials(payload);
                break;
            }
            default: {
                break;
            }
        }
    }

    resetFolders(payload: FolderItem[]) {
        this.currentFolders = payload;
        this.currentPage = this.readingMaterialStateStore.currentPageState[this.currentFolders.length];
        this.currentLimit = this.readingMaterialStateStore.currentLimitState[this.currentFolders.length];
        this.currentKeyword = this.readingMaterialStateStore.currentKeywordState[this.currentFolders.length];
        this.isPageSpecified = true;
        this.saveCurrentState();
        this.retrieveData();
    }

    updateReadingMaterialLevelName(payload: EditReadingMaterialLevelName) {
        this.readingMaterialDetailStore.updateReadingMaterialLevel(payload);
    }

    // Checkbox
    deleteSelectedMaterials(payload: number[]) {
        this.materialCheckboxes = payload;
        this.isDeleteButtonDisabled = this.materialCheckboxes.length > 0 ? false: true;
    }

    deleteSelectedItems() {
        this.readingMaterialDetailStore.deleteReadingMaterials(this.materialCheckboxes);
    }

    // Watch / Parse Function
    @Watch(`courseListStore.response`)
    parseCourseData() {
        this.currentCourses = [];
        if (this.courseListStore.response) {
            this.currentTotalCount = this.courseListStore.response.totalCount;
            this.courseListStore.response.objects.forEach((data) => {
                this.currentCourses.push({
                    id: data.id,
                    courseId: data.courseId,
                    name: `${data.courseName} - ${data.form.name} - ${moment(data.courseStartTime).format(`YYYY`)} - ${data.teacher.firstname} ${data.teacher.lastname}`,
                    numberOfCourses: -1,
                });
            });
            this.readingMaterialStateStore.updateReadingMaterialCourseState(this.currentCourses);
        }
    }

    @Watch(`readingMaterialListStore.levelResponse`)
    parseReadingMaterialLevelData() {
        this.currentReadingMaterialLevel = [];
        if (this.readingMaterialListStore.levelResponse) {
            this.currentTotalCount = this.readingMaterialListStore.levelResponse.totalCount;
            this.readingMaterialListStore.levelResponse.objects.forEach((data) => {
                this.currentReadingMaterialLevel.push(data);
            });
            this.readingMaterialStateStore.updateReadingMaterialLevelState(this.currentReadingMaterialLevel);
        }
    }

    @Watch(`readingMaterialListStore.response`)
    parseReadingMaterialData() {
        this.currentReadingMaterial = [];
        if (this.readingMaterialListStore.response) {
            this.currentTotalCount = this.readingMaterialListStore.response.totalCount;
            this.readingMaterialListStore.response.objects.forEach((data) => {
                this.currentReadingMaterial.push({
                    ...data,
                });
            });
            this.readingMaterialStateStore.updateReadingMaterialState(this.currentReadingMaterial);
        }
    }

    updateAvailability(payload: ReadingMaterialItem) {
        this.readingMaterialDetailStore.updateReadingMaterial(payload);
    }

    @Watch(`readingMaterialDetailStore.response`)
    updateReadingMaterialList() {
        if (this.readingMaterialDetailStore.response) {
            this.retrieveData();
        }
    }

    @Watch(`readingMaterialDetailStore.levelResponse`)
    updateReadingMaterialLevels() {
        if (this.readingMaterialDetailStore.levelResponse) {
            this.retrieveData();
        }
    }

    // Navigation Functions
    redirectToResourceLectures() {
        this.saveCurrentState();
        this.$router.push({
            path: `/resources/lectures`
        });
    }

    navigateToCreateResource() {
        this.saveCurrentState();
        this.$router.push({
            path: `/resources/create`
        });
    }

    // Folder Functions
    addFolderRouteForCourses(data: CourseListItem) {
        this.saveCurrentState();
        this.currentFolders.push({
            id: data.id,
            name: data.name,
        });
        this.currentKeyword = ``;
        this.currentPage = 1;
        this.isPaginationReset = true;
        this.readingMaterialStateStore.updateReadingMaterialFolderState(this.currentFolders);
        this.retrieveData();
    }

    addFolderRouteForReadingMaterialLevels(data: string) {
        this.saveCurrentState();
        this.currentFolders.push({
            id: -1,
            name: data,
        });
        this.currentKeyword = ``;
        this.currentPage = 1;
        this.isPaginationReset = true;
        this.readingMaterialStateStore.updateReadingMaterialFolderState(this.currentFolders);
        this.retrieveData();
    }

    // Delete
    deleteReadingMaterial(payload: ReadingMaterialItem) {
        this.readingMaterialDetailStore.deleteReadingMaterial(payload.id);
    }

    redirectToReadingMaterial(item: ReadingMaterialItem) {
        // Save State
        this.saveCurrentState();
        this.$router.push({
            path: `/resources/${item.id}/edit`,
        });
    }

    @Watch(`readingMaterialDetailStore.deleteResponse`)
    refetchReadingMaterial() {
        this.retrieveData();
    }

    // Save State
    saveCurrentState() {
        this.readingMaterialStateStore.updateReadingMaterialFolderState(this.folders);
        this.readingMaterialStateStore.updateReadingMaterialCourseState(this.courses);
        this.readingMaterialStateStore.updateReadingMaterialLevelState(this.readingMaterialLevels);
        this.readingMaterialStateStore.updateReadingMaterialState(this.readingMaterials);
        this.readingMaterialStateStore.updateReadingMaterialCurrentPage({ folderLevel: this.folders.length, value: this.currentPage});
        this.readingMaterialStateStore.updateReadingMaterialCurrentLimit({ folderLevel: this.folders.length, value: this.currentLimit});
        this.readingMaterialStateStore.updateReadingMaterialCurrentTotalCount({ folderLevel: this.folders.length, value: this.currentTotalCount});
        this.readingMaterialStateStore.updateReadingMaterialCurrentKeyword({ folderLevel: this.folders.length, value: this.currentKeyword});
    }
}
