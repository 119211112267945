







import { Vue, Component, Emit } from 'vue-property-decorator';
@Component({})
export default class UploadButton extends Vue {
  @Emit(`show`)
  showUploadForm() {
    return true;
  }
}
