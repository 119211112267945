





























import { NO_SPECIAL_CHARACTER_REGEX } from '@/utils/regex';
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
@Component({})
export default class CreateManagementItem extends Vue {
  currentData = '';
  isCreateButtonDisabled = true;
  showErrorText = false;

  @Prop({ required: true })
  placeholder!: string;

  @Prop()
  itemName!: string;

  @Prop({ default: false })
  isFormMode!: boolean;

  @Prop({ default: '' })
  errorText!: string;

  mounted() {
    this.currentData = this.itemName ? this.itemName : '';
  }

  updateCurrentName(payload: string) {
    this.currentData = payload;
    this.isCreateButtonDisabled = false;
    this.showErrorText = false;
    if (this.isFormMode && NO_SPECIAL_CHARACTER_REGEX.test(payload)) {
      this.isCreateButtonDisabled = true;
      this.showErrorText = true;
    }
  }

  @Emit(`update`)
  updateItemName() {
    return this.currentData;
  }

  @Emit(`close`)
  closeComponent() {
    this.currentData = ``;
    return false;
  }
}
