






import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class EmptyPlaceholder extends Vue {
  @Prop({ default: `No records found.` })
  placeholderText!: string;
}
