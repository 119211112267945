



































import { DropdownItem } from '@/models/dropdown';
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import { ChapterItemFormTemplateOutput } from '@/models/miscellaneous';
import { GeneralChapterItem } from '@/models/chapters';
@Component({
  components: { Dropdown },
})
export default class CreateChapterItem extends Vue {
  currentText = '';
  currentData: ChapterItemFormTemplateOutput = {
    id: -1,
    value: '',
    dropdownItem: {
      text: '',
      value: '',
      uniqueKey: '',
    },
  };
  currentDropdownItem: DropdownItem = {
    text: '',
    value: '',
    uniqueKey: '',
  };

  @Prop({ required: true })
  placeholder!: string;

  @Prop({ required: true })
  dropdownData!: DropdownItem[];

  @Prop()
  item!: GeneralChapterItem;

  selectedDataText = '- Select -';
  previousSelectedDataValue = '';

  get dropdownValues() {
    return this.dropdownData;
  }

  mounted() {
    this.currentText = this.item ? this.item.name : '';
    this.previousSelectedDataValue = this.item ? this.item.questionbankid.toString() : '';
    this.currentData.id = this.item ? this.item.id : -1;
  }

  updateCurrentName(payload: string) {
    this.currentText = payload;
  }

  updateSelection(data: DropdownItem) {
    this.selectedDataText = data.text;
    this.currentDropdownItem = data;
  }

  @Emit(`update`)
  updateItemName() {
    this.currentData.value = this.currentText;
    this.currentData.dropdownItem = this.currentDropdownItem;
    return this.currentData;
  }

  @Emit(`close`)
  closeComponent() {
    this.currentText = ``;
    return false;
  }
}
