













































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { MultiListItem } from '@/models/management';
import ManagementItemFormTemplate from '@/components/management/ManagementItemFormTemplate.vue';
import { padIdWithZeroes } from '@/utils/helper';
import ChapterItemFormTemplate from '@/components/management/ChapterItemFormTemplate.vue';
import { DropdownItem } from '@/models/dropdown';
import { GeneralChapterItem } from '@/models/chapters';
import { ChapterItemFormTemplateOutput } from '@/models/miscellaneous';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
@Component({
  components: { ManagementItemFormTemplate, ChapterItemFormTemplate, EmptyPlaceholder },
})
export default class ManagementList extends Vue {
  @Prop({ required: true })
  data!: MultiListItem[];

  @Prop({ required: true })
  placeholder!: string;

  @Prop({ default: false })
  isCourseMode!: boolean;

  @Prop({ default: false })
  isChapterMode!: boolean;

  @Prop({ default: false })
  isFormMode!: boolean;

  @Prop({ default: false })
  isQuestionBankMode!: boolean;

  @Prop({ default: '' })
  errorText!: string;

  @Prop()
  chapterDropdownData!: DropdownItem[];

  @Prop()
  previousCheckboxes!: number[];

  @Prop({ default: false })
  showEmptyPlaceholder!: boolean;

  @Prop({ default: true })
  isCheckboxAvailable!: boolean;

  @Prop({ default: false })
  isDisplayOnly!: boolean;

  itemToEdit = -1;
  checkboxSelection: number[] = [];

  get showPlaceholder() {
    return this.showEmptyPlaceholder;
  }

  mounted() {
    this.checkboxSelection = this.previousCheckboxes;
  }

  @Watch(`previousCheckboxes`)
  updateCurrentCheckboxes() {
    this.checkboxSelection = this.previousCheckboxes;
  }

  closeEditForm(payload: boolean) {
    if (!payload) {
      this.itemToEdit = -1;
    }
  }

  stringifyId(id: number) {
    return padIdWithZeroes(id);
  }

  @Emit(`editCourse`)
  redirectToEditCourse(item: MultiListItem) {
    return item;
  }

  @Emit(`editQuestionBank`)
  redirectToEditQuestionBank(item: MultiListItem) {
    return item;
  }

  @Emit(`checkboxUpdate`)
  updateCheckboxData() {
    return this.checkboxSelection;
  }

  @Emit(`update`)
  updateCurrentItem(payload: string) {
    const output: MultiListItem = {
      ...this.data[this.itemToEdit],
      name: payload,
    };
    this.closeEditForm(false);
    return output;
  }

  @Emit(`updateChapter`)
  updateCurrentChapterItem(payload: ChapterItemFormTemplateOutput) {
    const data: GeneralChapterItem = {
      id: payload.id,
      name: payload.value,
      questionbankid: Number(payload.dropdownItem.value),
    };
    this.closeEditForm(false);
    return data;
  }

  @Emit(`delete`)
  deleteItem(payload: MultiListItem) {
    return payload;
  }

  @Emit(`route`)
  updateFolderRoute(payload: MultiListItem) {
    return payload;
  }
}
